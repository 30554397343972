import React, { FC, Fragment } from 'react';
import { EnumsWithTranslations, getTranslationForEnum, TranslatableEnum } from './GetTranslationForEnum';

export interface TProps {
    enum: TranslatableEnum;
    children: EnumsWithTranslations;
}

export const T: FC<TProps> = props => {
    return <Fragment>{getTranslationForEnum(props.enum, props.children)}</Fragment>;
};
