import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { feedAddressSelector, feedCoordinatesSelector } from 'state/feed/selectors';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Dialog } from 'components/Dialog/Dialog';
import { Button, ButtonColor } from 'components/Button/Button';
import { GeoMap } from 'components/GeoMap/GeoMap';
import { useMap } from 'components/MapControl/useMap';
import debounce from 'lodash/debounce';
import i18n from '../../../i18n';

import { getRequest, postRequest } from 'core/axios/axios';
import { makeUrl } from 'core/utils/utils';

import { TextControl } from 'components/TextControl/TextControl';
import { MapControlAddressValidation } from 'components/MapControl/MapControlAddressValidation';
import { SelectControlDropdown } from 'components/SelectControl/SelectControlDropdown';
import { Text, TextTypography } from 'components/Text/Text';

import { Endpoint } from 'Endpoint';
import { Icon } from 'components/Icon/Icon';
import { ReactComponent as MapIcon } from 'assets/icons/main-16/map-marker--16.svg';
import { GoogleMapsLoadedObserver } from '../GoogleMapsLoadedObserver/GoogleMapsLoadedObserver';
import browserCookies from 'browser-cookies';
import { AddressDto, SupportedCountryCodes } from '../../types';
import css from './FormAddress.module.css';

export interface AutocompleteOption {
    structured_formatting: {
        main_text: string;
        secondary_text?: string;
    };
    address: AddressDto;
    place_id: string;
}

const getQcGeoFromAddress = ({ street, city }: AddressDto): number => {
    if (street?.number) {
        return 1;
    }
    if (street?.name) {
        return 2;
    }
    if (city) {
        return 4;
    }
    return 5;
};

const noop = () => {};

const getAutocompletePredictions = (
    input: string,
    set: (v: AutocompleteOption[]) => void,
): Promise<AutocompleteOption[]> => {
    const url = makeUrl(Endpoint.GET_ADDRESS, {
        input,
    });
    return getRequest(url)
        .then(response => {
            const { predictions } = response.data;

            set(predictions || []);
            return predictions || [];
        })
        .catch(noop);
};

interface Coordinates {
    lat: number;
    lon: number;
    qc_geo: number;
    internalQcGeo: number;
}

export interface FormAddressProps {
    label: string;
    input: {
        value: string;
        onChange: (value: string) => void;
    };
    meta: {
        error?: string;
    };
    onCoordinatesChange: (coordinates: Coordinates) => void;
    onStandardAddressChange?: (data: AddressDto) => void;
    isDisabled?: boolean;
    coordinates: { lat: number; lon: number; internalQcGeo: number };
    shouldDisplayMapInside?: boolean;
    selectControlDropdownWidth?: number;
}

export const FormAddress = ({
    label,
    onCoordinatesChange,
    onStandardAddressChange,
    isDisabled,
    input: { value, onChange },
    meta: { error },
    coordinates,
    shouldDisplayMapInside = false,
    selectControlDropdownWidth = 300,
}: FormAddressProps) => {
    const [qcGeo, setQcGeo] = useState(5);
    const [isDownOrientation, setIsDownOrientation] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [currentCoordinates, setCurrentCoordinates] = useState({ lat: 0, lon: 0, internalQcGeo: 0 });
    const [isMapOpen, setIsMapOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>();
    const inputRef = useRef<HTMLDivElement>();
    const [input, setInput] = useState(value);
    const country = browserCookies.get('country');

    const initialAddress = useSelector(feedAddressSelector);
    const initialCoordinates = useSelector(feedCoordinatesSelector);

    const { t } = i18n.useTranslation();
    const errorMessage = t(error);

    const { city, initLocation, coordinates: autoCoordinates } = useMap({
        onAddressChange: noop,
        onCoordinatesChange: noop,
        initialAddress,
        initialCoordinates,
    });

    useEffect(() => {
        setInput(value);
    }, [value]);

    useEffect(() => {
        setCurrentCoordinates(coordinates);
    }, [coordinates]);

    useEffect(() => {
        if (!value) {
            initLocation();
        }
    }, []);

    useEffect(() => {
        if (city && !value) {
            handleSetInput(city + ', ');
        }
    }, [city]);

    useEffect(() => {
        const { top } = containerRef.current?.getBoundingClientRect() || {};
        if (top < 400 || window.innerWidth < 640) {
            setIsDownOrientation(false);
        } else {
            setIsDownOrientation(true);
        }
    }, [containerRef, containerRef.current, isOpen]);

    const debouncedGetPredications = useMemo(() => {
        return debounce(
            v =>
                getAutocompletePredictions(v, ops => {
                    setOptions(ops);
                    setQcGeo(ops[0] ? getQcGeoFromAddress(ops[0].address) || 5 : 5);
                }),
            300,
        );
    }, []);

    const handleSetInput = useCallback((v: string) => {
        setInput(v);
        debouncedGetPredications(v);
    }, []);

    const handleOptionClick = useCallback(({ address, structured_formatting, place_id }: AutocompleteOption) => {
        const { main_text, secondary_text } = structured_formatting;
        if (!country || country === SupportedCountryCodes.RUS || country === SupportedCountryCodes.UZB) {
            // Dadata autocomplete version
            const { qc_geo, latitude: geo_lat, longitude: geo_lon } = address.geoCoordinates;
            setInput(main_text);
            const currentQc = getQcGeoFromAddress(address);
            setQcGeo(currentQc);
            if (inputRef.current) {
                const input = inputRef.current.querySelector('input');
                if (input) {
                    input.focus();
                }
            }
            if (currentQc < 2) {
                onCoordinatesChange({
                    internalQcGeo: Number(currentQc),
                    qc_geo: Number(qc_geo),
                    lon: Number(geo_lon),
                    lat: Number(geo_lat),
                });
                if (onStandardAddressChange) {
                    onStandardAddressChange(address);
                }
                setOptions([]);
                setIsOpen(false);
                setInput(main_text);
                //костыль, не трогайте плз)
                setTimeout(() => onChange(main_text), 0);
            }
        } else {
            // Standard address
            const url = makeUrl(Endpoint.GET_STANDARD_ADDRESS) + `?place_id=${place_id}`;

            const body = {
                address: {
                    addressString: main_text,
                },
            };
            postRequest(url, body).then(({ data }) => {
                const {
                    geoCoordinates: { latitude, longitude, qc_geo },
                    ...additionalData
                } = data;
                const fullText = secondary_text ? main_text + ', ' + secondary_text : main_text;
                setInput(fullText);
                setQcGeo(qc_geo);
                onCoordinatesChange({
                    internalQcGeo: 1,
                    qc_geo: Number(qc_geo),
                    lon: Number(longitude),
                    lat: Number(latitude),
                });
                if (onStandardAddressChange) {
                    onStandardAddressChange(additionalData);
                }
                setOptions([]);
                setIsOpen(false);
                setInput(fullText);
                //костыль, не трогайте плз)
                setTimeout(() => onChange(fullText), 0);
            });
        }
    }, []);

    const handleClosePopup = useCallback(() => {
        setIsOpen(false);
        //     setInput(options[0] ? options[0].structured_formatting.main_text : input);
        //     //костыль, не трогайте плз)
        //     setTimeout(() => onChange(options[0] ? options[0].structured_formatting.main_text : input), 0);
        //     const { qc_geo, geo_lon, geo_lat } = options[0]?.address || {};
        //     const currentAddressInternalQcGeo = getQcGeoFromAddress(options[0]?.address || {});
        //     console.log(options);
        //     console.log({
        //         internalQcGeo: currentAddressInternalQcGeo,
        //         qc_geo,
        //         lon: geo_lon,
        //         lat: geo_lat,
        //     });
        //     onCoordinatesChange({
        //         internalQcGeo: currentAddressInternalQcGeo,
        //         qc_geo,
        //         lon: geo_lon,
        //         lat: geo_lat,
        //     });
        //     setQcGeo(qc_geo);
    }, [options, input]);

    const handleInputOpen = useCallback(() => {
        setIsOpen(true);
        if (input && input.length > 3) {
            debouncedGetPredications(input);
        }
    }, [input]);

    const handleCurrentCoordinatesChange = useCallback(
        (lat: number, lon: number) => {
            setCurrentCoordinates({ lat, lon, internalQcGeo: currentCoordinates?.internalQcGeo });
        },
        [currentCoordinates?.internalQcGeo],
    );

    const handleSaveCoordinates = useCallback(() => {
        onCoordinatesChange({ ...currentCoordinates, qc_geo: 0 });
        setIsMapOpen(false);
        //костыль, не трогайте плз)
        setTimeout(() => onChange(input), 0);
    }, [currentCoordinates]);

    return (
        <div ref={containerRef}>
            <TextControl
                label={label}
                value={input}
                isDisabled={isDisabled || isMapOpen}
                disableAutocomplete={true}
                onClick={handleInputOpen}
                error={errorMessage}
                isReadOnly
                autoFocus={false}
            />
            {error === 'common:validation:clarifyCoordinates' && (
                <Text
                    typography={TextTypography.BODY_ACCENT}
                    color="#243143"
                    className={css.pickAddress}
                    onClick={() => {
                        setIsMapOpen(true);
                    }}
                >
                    <div className={css.pickAddressIcon}>
                        <Icon color="#243143" component={MapIcon} size={16} svgSize={16} />
                    </div>
                    {t('common:addressControl:clarifyCoordinates')}
                </Text>
            )}
            {isMapOpen && !shouldDisplayMapInside && (
                <Dialog
                    title={t('common:addressControl:choosePointOnMap')}
                    onClose={() => setIsMapOpen(false)}
                    width={600}
                >
                    <div className={css.mapContainer}>
                        <GoogleMapsLoadedObserver>
                            <GeoMap
                                lat={Number(coordinates?.lat) || autoCoordinates.lat}
                                lon={Number(coordinates?.lon) || autoCoordinates.lon}
                                onCenterChanged={handleCurrentCoordinatesChange}
                            />
                        </GoogleMapsLoadedObserver>
                    </div>
                    <Button color={ButtonColor.DARK} onClick={handleSaveCoordinates} className={css.okButton}>
                        {t('common:addressDialog.save')}
                    </Button>
                </Dialog>
            )}
            {isMapOpen && shouldDisplayMapInside && (
                <div className={css.mapContainer}>
                    <GoogleMapsLoadedObserver>
                        <GeoMap
                            lat={Number(coordinates?.lat) || autoCoordinates.lat}
                            lon={Number(coordinates?.lon) || autoCoordinates.lon}
                            onCenterChanged={handleCurrentCoordinatesChange}
                        />
                    </GoogleMapsLoadedObserver>
                    <Button color={ButtonColor.DARK} onClick={handleSaveCoordinates} className={css.okButton}>
                        {t('common:addressDialog.save')}
                    </Button>
                </div>
            )}
            {isOpen && (
                <SelectControlDropdown
                    anchorElement={containerRef.current}
                    width={selectControlDropdownWidth}
                    onClose={handleClosePopup}
                    orientation={isDownOrientation ? 'up' : 'down'}
                >
                    <div className={css.container}>
                        {options.length > 0 ? (
                            <div className={css.validation}>
                                <Text typography={TextTypography.BODY}>{t('common:addressControl.enter')}</Text>
                                {(!country || country === SupportedCountryCodes.RUS || country === SupportedCountryCodes.UZB) && (
                                    <MapControlAddressValidation
                                        hasStreetAddress={qcGeo <= 1}
                                        hasValue={qcGeo <= 2}
                                        hasCity={qcGeo <= 4 || input === city + ', '}
                                        cityLabel={t('common:addressControl:city')}
                                        addressLabel={t('common:addressControl:address')}
                                        houseNumberLabel={t('common:addressControl:houseNumber')}
                                    />
                                )}
                            </div>
                        ) : (
                            <div style={{ height: 63 }} />
                        )}
                        <div className={css.search} ref={inputRef}>
                            <TextControl
                                autoFocus={true}
                                label={label}
                                value={input}
                                isDisabled={isDisabled}
                                disableAutocomplete={true}
                                error={(!country || country === SupportedCountryCodes.RUS || country === SupportedCountryCodes.UZB) && errorMessage}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetInput(e.target.value)}
                            />
                        </div>
                        <List className={css.list}>
                            {options.length > 0 &&
                                options.map((option, i) => {
                                    const { main_text, secondary_text } = option.structured_formatting;
                                    const itemText = secondary_text ? main_text + ', ' + secondary_text : main_text;
                                    return (
                                        <ListItem key={i} button onClick={() => handleOptionClick(option)}>
                                            <ListItemText primary={<span>{itemText}</span>} />
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </div>
                </SelectControlDropdown>
            )}
        </div>
    );
};
