import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import debounce from 'lodash/debounce';

import { renderDefaultMap } from 'core/maps/maps';

import { MapControls } from './MapControls';

export interface GeoMapProps {
    lat: number;
    lon: number;
    onCenterChanged: (lat: number, lon: number) => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    map: {
        width: '100%',
        height: '100%',
    },
    pin: {
        backgroundColor: '#fff',
        border: '6px solid #000',
        borderRadius: '50%',
        height: 20,
        left: '50%',
        marginLeft: -10,
        marginTop: -10,
        position: 'absolute',
        top: '50%',
        width: 20,
    },
    mapControls: {
        position: 'absolute',
        right: theme.spacing(1),
        bottom: theme.spacing(6),
    },
}));

export const GeoMap = (props: GeoMapProps) => {
    const classes = useStyles();
    const mapElementRef = useRef<HTMLDivElement>();
    const mapRef = useRef<google.maps.Map>();
    const isMapInitializedRef = useRef(false);
    const { lat, lon, onCenterChanged } = props;

    useEffect(() => {
        if (isMapInitializedRef.current) {
            const center = mapRef.current.getCenter();
            const currentLat = center.lat();
            const currentLon = center.lng();
            if (currentLat !== lat || currentLon !== lon) {
                mapRef.current.setCenter({ lat, lng: lon });
            }
        } else {
            if (lat === null || lon === null) {
                return;
            }
            isMapInitializedRef.current = true;
            const map = renderDefaultMap(mapElementRef.current, lat, lon);
            const centerChangedHandler = () => {
                const center = map.getCenter();
                const lat = center.lat();
                const lon = center.lng();
                onCenterChanged(lat, lon);
            };
            centerChangedHandler();
            map.addListener('center_changed', debounce(centerChangedHandler, 300));
            mapRef.current = map;
        }
    }, [lat, lon, onCenterChanged]);

    return (
        <div className={classes.container}>
            <div ref={r => (mapElementRef.current = r)} className={classes.map} />
            <div className={classes.pin} />
            <div className={classes.mapControls}>
                <MapControls
                    onZoomIn={() => {
                        if (mapRef.current) {
                            const zoom = mapRef.current.getZoom();
                            mapRef.current.setZoom(zoom + 1);
                        }
                    }}
                    onZoomOut={() => {
                        if (mapRef.current) {
                            const zoom = mapRef.current.getZoom();
                            mapRef.current.setZoom(zoom - 1);
                        }
                    }}
                />
            </div>
        </div>
    );
};
