import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Text, TextTypography } from 'components/Text/Text';

const useStyles = makeStyles({
    container: {
        textAlign: 'center',
    },
    green: {
        color: '#25da4d',
    },
    red: {
        color: '#EA1103',
    },
});

export interface MapControlAddressValidationProps {
    hasValue: boolean;
    hasStreetAddress: boolean;
    addressLabel: string;
    houseNumberLabel: string;
    //city
    hasCity?: boolean;
    cityLabel?: string;
}

export const MapControlAddressValidation = (props: MapControlAddressValidationProps) => {
    const classes = useStyles();
    const { hasValue, hasStreetAddress, addressLabel, houseNumberLabel, cityLabel, hasCity } = props;

    const hasAddressColor = hasValue ? classes.green : classes.red;
    const hasHouseNumberColor = hasStreetAddress ? classes.green : classes.red;
    const hasCityColor = hasCity ? classes.green : classes.red;

    return (
        <div className={classes.container}>
            <Text typography={TextTypography.PARA}>
                {cityLabel && (
                    <>
                        <span className={hasCityColor}>{cityLabel}</span>,{' '}
                    </>
                )}
                <span className={hasAddressColor}>{addressLabel}</span>,{' '}
                <span className={hasHouseNumberColor}>{houseNumberLabel}</span>
            </Text>
        </div>
    );
};
