import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from '../Icon/Icon';

import { ReactComponent as PlusIcon } from 'assets/icons/main-16/plus--16.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/main-16/minus--16.svg';
import { ReactComponent as LocationIcon } from 'assets/glyphs/location.svg';

const useStyles = makeStyles(theme => ({
    button: {
        borderRadius: 16,
        cursor: 'pointer',
        padding: 8,
        width: 32,
        height: 32,
        backgroundColor: theme.palette.fixedPrimary.primary,
    },
    firstSpacer: {
        height: theme.spacing(1),
    },
    secondSpacer: {
        height: theme.spacing(12),
    },
}));

export interface MapControlsProps {
    onZoomIn: () => void;
    onZoomOut: () => void;
    onDetectLocation: () => void;
}

export const MapControls = (props: MapControlsProps) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.button} onClick={props.onZoomIn}>
                <Icon component={PlusIcon} size={16} svgSize={16} color="#fff" />
            </div>
            <div className={classes.firstSpacer} />
            <div className={classes.button} onClick={props.onZoomOut}>
                <Icon component={MinusIcon} size={16} svgSize={16} color="#fff" />
            </div>
            <div className={classes.secondSpacer} />
            <div className={classes.button} onClick={props.onDetectLocation}>
                <Icon component={LocationIcon} size={16} svgSize={16} color="#fff" />
            </div>
        </div>
    );
};
