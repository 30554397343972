import React, { FC, ReactNode } from 'react';
import { Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface StageItemProps {
    label: ReactNode;
    // Optional:
    component?: any;
    selected?: boolean;
    to?: string;
    onClick?: () => void;
    className?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 0,
        maxWidth: 320,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        opacity: 0.5,
    },
    selected: {
        opacity: 1,
    },
}));

export const StageItem: FC<StageItemProps> = props => {
    const classes = useStyles();
    return <Tab className={props.className} classes={classes} {...props} />;
};
