import React, { FC, Fragment, ReactNode } from 'react';
import { Box } from '@material-ui/core';

import { BackButton } from 'components/BackButton/BackButton';
import { FullScreenDialog } from 'components/FullScreenDialog/FullScreenDialog';

export interface SelectControlMobileDropdownProps {
    onClose: () => void;
}

export const SelectControlMobileDropdown: FC<SelectControlMobileDropdownProps> = props => {
    return (
        <FullScreenDialog onClose={props.onClose}>
            {({ onClose }) => (
                <Fragment>
                    <Box m={2}>
                        <BackButton onClick={onClose} />
                    </Box>
                    <Box m={2}>{props.children}</Box>
                </Fragment>
            )}
        </FullScreenDialog>
    );
};
