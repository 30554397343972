import React, { FC } from 'react';
import { Dialog as MaterialDialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface FullScreenDialogProps {
    children: ({ onClose }: { onClose: () => void }) => JSX.Element;
    onClose: () => void;
    className?: string;
}

export const FullScreenDialog: FC<FullScreenDialogProps> = props => {
    const { onClose, className } = props;
    return (
        <MaterialDialog
            open={true}
            onClose={onClose}
            fullScreen={true}
            classes={{ paper: className }}
            style={{ backgroundColor: 'rgba(36, 49, 67, 0.75)' }}
        >
            {props.children({ onClose })}
        </MaterialDialog>
    );
};
