import React, { FC, ReactNode } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { SelectControlMobileDropdown } from './SelectControlMobileDropdown';
import { SelectControlDesktopDropdown } from './SelectControlDesktopDropdown';

export interface SelectControlDropdownProps {
    anchorElement: HTMLElement;
    onClose: () => void;
    width: number;
    orientation?: 'down' | 'up';
}

export const SelectControlDropdown: FC<SelectControlDropdownProps> = props => {
    const theme = useTheme();

    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    return isLargeScreen ? (
        <SelectControlDesktopDropdown {...props} />
    ) : (
        <SelectControlMobileDropdown children={props.children} onClose={props.onClose} />
    );
};
