import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { makeUrl } from '../utils/utils';

import { RoutePath } from 'RoutePath';

const isServer = typeof window === 'undefined';

export const useRouterNavigation = (items: string[]) => {
    if (!isServer) {
        const match = useRouteMatch(items);
        return match ? items.findIndex(r => r === match.path) : 0;
    }
};

export const useInitialRouterNavigation = (items: string[]) => {
    if (!isServer) {
        const initialValue = useRouterNavigation(items);
        return useState(initialValue);
    } else {
        return useState(null);
    }
};

export const useRouterParam = <T>(key: keyof T) => {
    if (!isServer) {
        const match = useRouteMatch<T>();
        return match.params[key];
    }
};

export const makeRoute = <T extends {}>(routePath: RoutePath, params: T) => {
    if (!isServer) {
        return makeUrl(routePath, params);
    }
};

export const diff = (parent: RoutePath, child: RoutePath): string => {
    return parent.replace(child, '').replace(/^\//, '');
};
