import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from 'components/Icon/Icon';
import { ReactComponent as CloseIcon } from 'assets/icons/main-16/times--16.svg';

export interface CloseButtonProps {
    onClick: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        cursor: 'pointer',
        backgroundColor: theme.palette.brandPrimary.main,
        borderRadius: 16,
        height: 32,
        padding: 8,
        width: 32,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.mainHover,
        },
    },
    icon: {
        display: 'block',
        color: theme.palette.fixedWhite.primary,
        height: 16,
        width: 16,
    },
}));

export const CloseButton: FC<CloseButtonProps> = props => {
    const classes = useStyles();
    return (
        <div onClick={props.onClick} className={classes.container}>
            <Icon className={classes.icon} color={'#fff'} component={CloseIcon} size={16} svgSize={16} />
        </div>
    );
};
