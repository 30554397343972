import React, { ChangeEvent, FC } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { WrappedFieldProps } from 'redux-form';
import i18n from '../../../i18n';
import { makeStyles } from '@material-ui/core/styles';

export interface TextControlProps {
    label: any;
    // Optional:
    id?: string;
    name?: string;
    value?: string;
    onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    fullWidth?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    autoCapitalize?: string;
    autoFocus?: boolean;
    adornmentStart?: string;
    onKeyPress?: (event: any) => void;
    onClick?: (event: any) => void;
    disableAutocomplete?: boolean;
}

const useInputStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
        paddingLeft: 0,
    },
}));

export const TextControl: FC<TextControlProps> = props => {
    const {
        error,
        adornmentStart,
        autoCapitalize,
        autoFocus,
        isDisabled,
        isReadOnly,
        disableAutocomplete,
        ...rest
    } = props;
    const inputClasses = useInputStyles();
    return (
        <TextField
            InputProps={{
                classes: inputClasses,
                disableUnderline: true,
                startAdornment: adornmentStart && (
                    <InputAdornment style={{ paddingLeft: 10 }} position="start">
                        {adornmentStart}
                    </InputAdornment>
                ),
                readOnly: isReadOnly,
                autoComplete: disableAutocomplete ? '__disableAutocompleteHack__' : undefined,
            }}
            autoFocus={autoFocus}
            inputProps={{ autoCapitalize }}
            variant="filled"
            error={!!error}
            helperText={error}
            fullWidth={true}
            size="small"
            disabled={isDisabled}
            {...rest}
        />
    );
};

export interface TextControlFieldOwnProps {
    label: string;
    // Optional
    adornmentStart?: string;
    autoCapitalize?: string;
    autoFocus?: boolean;
}

export type TextControlFieldProps = TextControlFieldOwnProps & WrappedFieldProps;

export const TextControlField = (props: TextControlFieldProps) => {
    const { t } = i18n.useTranslation();
    const {
        input,
        label,
        adornmentStart,
        autoCapitalize,
        autoFocus,
        meta: { touched, invalid, error },
        ...rest
    } = props;
    return (
        <TextControl
            label={label}
            adornmentStart={adornmentStart}
            autoCapitalize={autoCapitalize}
            autoFocus={autoFocus}
            error={touched && invalid ? t(error) : undefined}
            {...input}
            {...rest}
        />
    );
};
