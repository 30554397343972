import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog as MaterialDialog, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import classNames from 'classnames';
import { ErrorBoundary } from 'components/ErrorBoundary';

import { Text, TextTypography } from '../Text/Text';
import { CloseButton } from '../CloseButton/CloseButton';

export interface DialogProps {
    // Optional:
    title?: string;
    marginTop?: number;
    contentStyles?: string;
    titleStyles?: string;
    fullHeight?: boolean;
    hideCloseButton?: boolean;
    onClose?: () => void;
    width?: number;
    subTitle?: ReactNode;
    disableContentPadding?: boolean;
    titleTypography?: TextTypography;
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        height: theme.spacing(4),
        position: 'absolute',
        right: theme.spacing(3),
        top: theme.spacing(3),
        width: theme.spacing(4),
    },
    content: {
        padding: 0,
        flexShrink: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: (props: Partial<DialogProps>) => (props.disableContentPadding ? 0 : theme.spacing(2)),
            paddingRight: (props: Partial<DialogProps>) => (props.disableContentPadding ? 0 : theme.spacing(2)),
        },
    },
    title: {
        paddingTop: 28,
        paddingBottom: 28,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid rgba(15, 24, 37, 0.05)',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
}));

const useDialogStyles = makeStyles(theme => {
    const marginTop = theme.spacing(5);
    const marginTopDesktop = theme.spacing(9);
    const marginBottomDesktop = theme.spacing(3);
    return {
        paper: {
            borderTopLeftRadius: theme.spacing(2),
            borderTopRightRadius: theme.spacing(2),
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            alignSelf: 'flex-end',
            margin: 0,
            marginTop: marginTop,
            width: '100vw',
        },
        paperScrollPaper: {
            maxHeight: `calc(100vh - ${marginTop}px)`,
            height: (props: DialogProps) => (props.fullHeight ? '100%' : 'auto'),
        },
        [theme.breakpoints.up('sm')]: {
            paper: {
                alignSelf: 'flex-start',
                borderRadius: 20,
                marginTop: (props: DialogProps) => props.marginTop ?? marginTopDesktop,
                marginBottom: marginBottomDesktop,
                width: (props: DialogProps) => props.width ?? 400,
                maxWidth: '90vw',
            },
            paperScrollPaper: {
                maxHeight: `calc(100vh - ${marginTopDesktop + marginBottomDesktop}px)`,
            },
        },
    };
});

export const Dialog: FC<DialogProps> = (props: PropsWithChildren<DialogProps>) => {
    const classes = useStyles(props);
    const dialogClasses = useDialogStyles(props);
    const { subTitle } = props;
    return (
        <ErrorBoundary>
            <MaterialDialog open={true} onClose={props.onClose} classes={dialogClasses}>
                {props.title && (
                    <DialogTitle className={classNames(classes.title, props.titleStyles)} disableTypography={true}>
                        <Text typography={props.titleTypography || TextTypography.LEAD_ACCENT}>{props.title}</Text>
                        {!props.hideCloseButton && (
                            <div className={classes.closeButton}>
                                <CloseButton onClick={props.onClose} />
                            </div>
                        )}
                        {subTitle}
                    </DialogTitle>
                )}
                <DialogContent className={classNames(classes.content, props.contentStyles)}>
                    {props.children}
                </DialogContent>
            </MaterialDialog>
        </ErrorBoundary>
    );
};
