import React, { FC, useMemo } from 'react';
import { Box, Popover, useTheme } from '@material-ui/core';

export interface SelectControlDesktopDropdownProps {
    anchorElement: HTMLElement;
    onClose: () => void;
    width: number;
    orientation?: 'down' | 'up';
}

export const SelectControlDesktopDropdown: FC<SelectControlDesktopDropdownProps> = props => {
    const { anchorElement, children, onClose, width, orientation = 'up' } = props;

    const theme = useTheme();

    const anchorPosition = useMemo(() => {
        const rect = anchorElement?.getBoundingClientRect();
        if (orientation === 'up') {
            return {
                left: rect?.left - theme.spacing(1),
                bottom: window.innerHeight - rect?.bottom - theme.spacing(1),
            };
        }
        return {
            left: rect?.left - theme.spacing(1),
            top: rect?.top - theme.spacing(8),
        };
    }, [orientation]);

    return (
        <Popover
            anchorReference="none"
            open={true}
            PaperProps={{
                style: {
                    ...anchorPosition,
                    maxWidth: '100%',
                },
            }}
            onClose={onClose}
        >
            <Box p={1} style={{ width: width + theme.spacing(2) }}>
                {children}
            </Box>
        </Popover>
    );
};
