import {
    DayOfWeek,
    DeliveryType,
    OrderStatus,
    PaymentType,
    StoreCategoryFilter,
    UnitType,
    YandexCheckoutApplicationStatus,
} from '../../types';
import i18n from '../../../i18n';

export enum TranslatableEnum {
    ORDER_STATUS = 'ORDER_STATUS',
    ORDER_STATUS_COMPLETE = 'ORDER_STATUS_COMPLETE',
    ORDER_STATUS_CANCELLED = 'ORDER_STATUS_CANCELLED',
    UNIT_TYPE = 'UNIT_TYPE',
    DELIVERY_TYPE = 'DELIVERY_TYPE',
    PAYMENT_TYPE = 'PAYMENT_TYPE',
    DAY_OF_WEEK = 'DAY_OF_WEEK',
    DAY_OF_WEEK_BY_NUMBER = 'DAY_OF_WEEK_BY_NUMBER',
    STORE_CATEGORY = 'STORE_CATEGORY',
    STORE_CATEGORY_SHORT = 'STORE_CATEGORY_SHORT',
    YA_APPLICATION_STATUS = 'ADMIN_YA_APPLICATION_STATUS',
    STORE_TYPE_RATE = 'STORE_TYPE_RATE',
}

export type EnumsWithTranslations =
    | OrderStatus
    | DeliveryType
    | PaymentType
    | UnitType
    | DayOfWeek
    | StoreCategoryFilter
    | YandexCheckoutApplicationStatus
    | number
    | string;

export function getTranslationForEnum(
    dictionary: TranslatableEnum,
    text: EnumsWithTranslations,
    translationFunc?: (text: string) => string,
): string {
    const { t } = translationFunc ? { t: translationFunc } : i18n.useTranslation();
    return t(dictionary && (text || text === 0) ? `common:enums.${dictionary}.${text}` : '');
}
