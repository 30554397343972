import { change } from 'redux-form';
import { string } from 'yup';

import { appConfig } from 'config/app';

import { postRequest } from 'core/axios/axios';
import { makeUrl } from 'core/utils/utils';
import { getErrorForFieldOrGeneric } from 'core/form/form';

import { Form } from 'forms/types';

import { Endpoint } from 'Endpoint';

/*

address: "г Москва, ул Туристская, д 19 к 1"
coordinates:
internalQcGeo: 0
lat: 55.852263
lon: 37.421156
qc_geo: 0
[[Prototype]]: Object
standardAddress:
addressId: null
city: {name: 'г Москва', sublocality: null}
comment: null
country: "Россия"
doorCode: null
formattedAddress: "г Москва, ул Туристская, д 19 к 1"
geoCoordinates: {latitude: 55.852263, longitude: 37.421156, qc_geo: 0}
region: "г Москва"
street: {name: 'ул Турист
 */

export const makeAddressValidator = (formName: Form, fieldName: string) => (
    // TODO: type as { [fieldName]: string }
    allV: { coordinates: any; address: string },
    dispatch: (v: any) => void,
): Promise<void> => {
    if (!allV?.coordinates?.internalQcGeo || !(allV?.coordinates?.internalQcGeo < 2))
        return Promise.reject({ [fieldName]: 'common:validation:addressFormat' });

    if (allV?.coordinates?.qc_geo == null || allV?.coordinates?.qc_geo > 1) {
        return Promise.reject({ [fieldName]: 'common:validation:clarifyCoordinates' });
    }

    if (allV?.address && allV?.coordinates?.lat) {
        const [city, ...addressArr] = allV.address.split(', ');
        const url = makeUrl(Endpoint.VALIDATE_ADDRESS);
        return postRequest(url, {
            city: {
                name: city,
            },
            street: {
                name: addressArr.join(', '),
            },
            geoCoordinates: {
                latitude: allV?.coordinates?.lat,
                longitude: allV?.coordinates?.lon,
                qc_geo: allV?.coordinates?.qc_geo,
            },
        })
            .then(({ data }) => {
                if (data.latitude) {
                    dispatch(
                        change(formName, 'coordinates', {
                            lat: data.latitude,
                            lon: data.longitude,
                            qc_geo: data.qc_geo,
                            internalQcGeo: 1,
                        }),
                    );
                }
            })
            .catch(e => {
                const msg = getErrorForFieldOrGeneric(e, '');
                throw { [fieldName]: msg };
            });
    }
    if (!allV?.address) {
        throw { [fieldName]: 'common:validation.required' };
    }
    return new Promise(() => {});
};

export const customUrlWithReservedNamesValidator = string()
    .required('common:validation.required')
    .matches(/^[a-zA-Z0-9-]*$/, 'onboarding:storeDetails:errors:emailFormat')
    .test('reservedNames', 'onboarding:storeDetails:errors:reservedName', (value: string) => {
        return !appConfig.reservedUrlNames.some(reservedName => reservedName === value?.toLowerCase());
    });

export const customUrlValidator = string()
    .required('common:validation.required')
    .matches(/^[a-zA-Z0-9-]*$/, 'onboarding:storeDetails:errors:emailFormat');
