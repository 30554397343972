import { captureException } from '@sentry/nextjs';
import { useCallback, useEffect, useRef, useState } from 'react';

import { appConfig } from 'config/app';

import { splitOnce } from 'core/utils/utils';
import { geocode, getServerGeolocation, reverseGeocode } from 'core/maps/maps';
import i18n from '../../../i18n';

export interface UseMapOptions {
    initialAddress: string;
    initialCoordinates: { lat: number; lon: number };
    onAddressChange: (value: string) => void;
    onCoordinatesChange: (coordinates: { lat: number; lon: number }, city: string, streetAddress: string) => void;
}

const getCombinedValue = (city: string, streetAddress: string) => {
    return city && streetAddress ? `${city}, ${streetAddress}` : '';
};

export const useMap = (options: UseMapOptions) => {
    const { initialAddress, onAddressChange, onCoordinatesChange, initialCoordinates } = options;
    const [initialCity, initialStreetAddress] = splitOnce(initialAddress || '', ', ');
    const [city, setCity] = useState(initialCity || '');
    const [streetAddress, setStreetAddress] = useState(initialStreetAddress || '');
    const [isGeolocationPending, setIsGeolocationPending] = useState(false);
    const [coordinates, setCoordinates] = useState(initialCoordinates);
    const { t } = i18n.useTranslation();

    useEffect(() => {
        setCoordinates(initialCoordinates);
    }, [initialCoordinates]);

    const locate = useCallback(() => {
        return getServerGeolocation()
            .catch(() => {
                return {
                    ...appConfig.defaultCoordinates,
                    city: appConfig.defaultCityName,
                };
            })
            .then(({ lat, lon, city }) => {
                if (lat === 0 && lon === 0 && !city) {
                    setCoordinates(appConfig.defaultCoordinates);
                    setCity(appConfig.defaultCityName);
                } else {
                    setCoordinates({ lat, lon });
                    setCity(city);
                }
                setIsGeolocationPending(false);
                return { lat, lon };
            })
            .then(({ lat, lon }: { lat: number; lon: number }) => reverseGeocode(lat, lon))
            .then(geocodeResult => {
                const { cityName } = geocodeResult;
                setCity(cityName);
            })
            .catch(console.log);
    }, []);
    const initLocation = useCallback(() => {
        setIsGeolocationPending(true);
        locate()
            .then(() => setIsGeolocationPending(false))
            .catch(console.log);
    }, [locate, setIsGeolocationPending]);
    const isStreetAddressBlocked = useRef(false);
    const onSetCity = useCallback(
        async (selectedCity: string) => {
            if (selectedCity === city) {
                return;
            }
            isStreetAddressBlocked.current = true;
            setCity(selectedCity);
            setStreetAddress('');
            try {
                const coordinates = await geocode(selectedCity);
                if (coordinates) {
                    setCoordinates(coordinates);
                }
            } catch (e) {
                captureException(e);
                console.log(t('feed:errors.city'));
            }
        },
        [city, isStreetAddressBlocked, setCity, setStreetAddress, setCoordinates],
    );
    const onSetStreetAddress = useCallback(
        async (selectedStreetAddress: string, skipSetCoordinates = false) => {
            if (selectedStreetAddress === streetAddress) {
                return;
            }
            setStreetAddress(selectedStreetAddress);
            try {
                const address = `${city}, ${selectedStreetAddress}`;
                if (!skipSetCoordinates) {
                    const coordinates = await geocode(address);
                    if (coordinates) {
                        setCoordinates(coordinates);
                    }
                }
            } catch (e) {
                captureException(e);
                console.log(t('feed:errors.coordinates'));
            }
        },
        [city, streetAddress, setStreetAddress, setCoordinates],
    );
    const onCenterChange = useCallback(
        async (lat: number, lon: number) => {
            if (lat === coordinates.lat && lon === coordinates.lon) {
                return;
            }
            setCoordinates({ lat, lon });
            if (isStreetAddressBlocked.current) {
                isStreetAddressBlocked.current = false;
                return;
            }
            try {
                const { cityName, streetAddress } = await reverseGeocode(lat, lon);
                setCity(cityName);
                setStreetAddress(streetAddress);
            } catch (e) {
                captureException(e);
                setCity('');
                setStreetAddress('');
            }
        },
        [coordinates, setCoordinates, setCity, setStreetAddress],
    );
    const onOk = useCallback(() => {
        const value = getCombinedValue(city, streetAddress);
        onAddressChange(value);
        if (onCoordinatesChange) {
            onCoordinatesChange(coordinates, city, streetAddress);
        }
    }, [city, streetAddress, coordinates, onCoordinatesChange, onAddressChange]);
    return {
        city,
        streetAddress,
        coordinates,
        isGeolocationPending,
        onSetCity,
        onSetStreetAddress,
        onCenterChange,
        onOk,
        initLocation,
    };
};
