import { Text, TextTypography } from '../Text/Text';
import { Button as MaterialButton } from '@material-ui/core';
import { Icon } from 'components/Icon/Icon';
import React, { FC } from 'react';
import css from './LargeButton.module.css';
import classNames from 'classnames';

export enum LargeButtonColor {
    TRANSPARENT,
    WHITE,
    LIGHT,
    SUCCESS,
    ERROR,
    GRAY,
}

export interface LargeButtonProps {
    // Optional:
    color?: LargeButtonColor;
    icon?: unknown;
    disabled?: boolean;
    onClick?: () => void;
    type?: 'submit';
    fitWidth?: boolean;
}

export const LargeButton: FC<LargeButtonProps> = props => {
    const { children, color, icon, fitWidth, ...rest } = props;
    const lbColor = LargeButtonColor[color];
    const textColorClassName = css['text_' + lbColor] || '';
    const backgroundColorClassName = css['background_color_' + lbColor] || css.background_color;
    const justifyContentClassName = css[!icon ? 'jc_center' : 'jc_space_between'];
    const hoverClassName = css['hov_' + lbColor] || css.hov;

    return (
        <MaterialButton
            className={classNames(css.button, backgroundColorClassName, justifyContentClassName, hoverClassName)}
            variant="contained"
            disableElevation={true}
            fullWidth={!fitWidth}
            {...rest}
        >
            {icon && <div className={css.emptyBox} />}
            <Text className={classNames(css.text, textColorClassName)} typography={TextTypography.PARA_ACCENT}>
                {children}
            </Text>
            {icon && <Icon className={css.icon} component={icon} size={16} svgSize={16} color="#fff" />}
        </MaterialButton>
    );
};
