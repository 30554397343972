import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../../core/utils/utils';
import { SupportedCountryCodes } from '../../types';

export interface CurrencyProps {
    children: number;
    // Optional:
    bold?: boolean;
    suffix?: string;
    isoCode?: SupportedCountryCodes;
}

const useStyles = makeStyles({
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export const Currency: FC<CurrencyProps> = props => {
    const { children, bold, isoCode } = props;

    const price = formatCurrency(children, isoCode);

    const classes = useStyles();

    return bold ? (
        <b className={classes.text}>{price}</b>
    ) : (
        <span className={classes.text}>{price}</span>
    );
};
