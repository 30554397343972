import React, { FC } from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import i18n from '../../../i18n';
import { IGNORE_ERROR } from '../PasswordControl/PasswordControl';

export interface FormErrorMessageProps {
    error: string | Record<string, string>;
    // Optional:
    title?: string;
}

export const FormErrorMessage: FC<FormErrorMessageProps> = props => {
    const { error, title } = props;
    const { t } = i18n.useTranslation();
    let message = '';
    if (typeof error === 'string') {
        message = error && error.startsWith('common:errors') ? t(error) : error;
    } else {
        const errors = Object.values(error)
        message = (errors.length !== 0) && errors[0] && errors[0] !== IGNORE_ERROR && t(errors[0])
    }
    return (
        <Alert severity="error">
            {title && <AlertTitle>{title}</AlertTitle>}
            {message}
        </Alert>
    );
};
