import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface FooterProps {
    // Optional:
    className?: string;
}

const useStyles = makeStyles(theme => ({
    container: {
        borderTop: `1px solid ${theme.palette.brandPrimary.accent}`,
        paddingBottom: theme.spacing(2),
    },
}));

export const Footer: FC<FooterProps> = ({ className, children }) => {
    const classes = useStyles();
    return <div className={clsx(classes.container, className)}>{children}</div>;
};
