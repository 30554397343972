import React, { FC, useState } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Icon } from '../Icon/Icon';

import { ReactComponent as ShowIcon } from 'assets/icons/main-16/show--16.svg';
import { ReactComponent as HideIcon } from 'assets/icons/main-16/hide--16.svg';
import { WrappedFieldProps } from 'redux-form';
import i18n from '../../../i18n';

export interface TextControlProps {
    label: string;
    // Optional:
    autoFocus?: boolean;
    name?: string;
    value?: string;
    onChange?: any;
    errorMessage?: string;
    fullWidth?: boolean;
    isDisabled?: boolean;
}

const useInputStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
    },
}));

const useLabelStyles = makeStyles({
    root: {
        transform: 'translate(12px, 15px)',
    },
});

const useAdornmentStyles = makeStyles({
    root: {
        width: 16,
        height: 16,
    },
});

export const IGNORE_ERROR = 'IGNORE_ERROR';

export const PasswordControl: FC<TextControlProps> = props => {
    const [isMasked, setIsMasked] = useState(true);
    const type = isMasked ? 'password' : 'text';
    const inputClasses = useInputStyles();
    const labelClasses = useLabelStyles();
    const adornmentClasses = useAdornmentStyles();
    const { autoFocus, errorMessage, ...rest } = props;
    return (
        <TextField
            InputProps={{
                type,
                disableUnderline: true,
                classes: inputClasses,
                endAdornment: (
                    <InputAdornment classes={adornmentClasses} position="end" onClick={() => setIsMasked(!isMasked)}>
                        <Icon component={isMasked ? HideIcon : ShowIcon} size={16} svgSize={16} />
                    </InputAdornment>
                ),
            }}
            InputLabelProps={{ classes: labelClasses }}
            variant="filled"
            error={!!errorMessage}
            helperText={errorMessage !== IGNORE_ERROR ? errorMessage : null}
            fullWidth={true}
            size="small"
            autoFocus={autoFocus}
            {...rest}
        />
    );
};

export interface PasswordControlFieldOwnProps {
    label: string;
    // Optional:
    autoFocus: boolean;
}

export type PasswordControlFieldProps = PasswordControlFieldOwnProps & WrappedFieldProps;

export const PasswordControlField = (props: PasswordControlFieldProps) => {
    const { t } = i18n.useTranslation();
    const {
        autoFocus,
        input,
        label,
        meta: { touched, invalid, error },
    } = props;
    return (
        <PasswordControl
            autoFocus={autoFocus}
            label={label}
            errorMessage={touched && invalid ? t(error) : undefined}
            {...input}
        />
    );
};
