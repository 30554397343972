import { AppSlice } from './reducer';

export const isUIEnabledSelector = (state: AppSlice) => {
    return state.app.isUIEnabled;
};

export const appInitStatusSelector = (state: AppSlice) => {
    return state.app.initStatus;
};

export const appInitSellingFailureSelector = (state: AppSlice) => {
    return state.app.errorSelling;
};
