import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as BackIcon } from 'assets/icons/main-16/chevron-left--16.svg';

export interface BackButtonProps {
    onClick: () => void;
}

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.fixedWhite.secondary,
        borderRadius: 16,
        height: 32,
        padding: 8,
        width: 32,
        '&:hover': {
            backgroundColor: theme.palette.brandPrimary.mainHover,
        },
    },
    icon: {
        display: 'block',
        fill: theme.palette.brandPrimary.main,
        height: 16,
        width: 16,
        '&:hover': {
            fill: theme.palette.fixedWhite.primary,
        },
    },
}));

export const BackButton: FC<BackButtonProps> = props => {
    const classes = useStyles();
    return (
        <div className={classes.container} onClick={props.onClick}>
            <SvgIcon className={classes.icon} component={BackIcon} viewBox="0 0 16 16" />
        </div>
    );
};
