import React, { FC, ReactElement, Ref } from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { StageItemProps } from './StageItem';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    divider: {
        borderBottom: `1px solid ${theme.palette.brandPrimary.accent}`,
        marginLeft: 'calc(50% - 50vw)',
        overflow: 'hidden',
    },
}));

const useTabsStyles = makeStyles(theme => ({
    root: {
        marginLeft: -theme.spacing(1),
        marginRight: -theme.spacing(1),
        width: '100%',
    },
    indicator: {
        backgroundColor: 'transparent',
        display: 'flex',
        height: 3,
        justifyContent: 'center',
        '& > span': {
            backgroundColor: theme.palette.fixedPrimary.primary,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '100%',
        },
    },
}));

export interface StageProps {
    children: ReactElement<StageItemProps>[];
    value: number;
    onChange: (value: number) => void;
    // Optional:
    hideDivider?: boolean;
    showScrollButtons?: boolean;
    styles?: CSSProperties;
    customDividerStyles?: CSSProperties;
    innerRef?: Ref<HTMLDivElement>;
}

export const Stage: FC<StageProps> = props => {
    const { onChange, showScrollButtons, styles, hideDivider, customDividerStyles, innerRef, ...rest } = props;
    const classes = useStyles();
    const tabsClasses = useTabsStyles();
    const scrollButtons = showScrollButtons ? 'on' : 'auto';
    return (
        <div className={classes.root} style={styles} ref={innerRef}>
            <Tabs
                classes={tabsClasses}
                indicatorColor="primary"
                value={props.value}
                onChange={(_, v) => onChange(v)}
                variant="scrollable"
                scrollButtons={scrollButtons}
                TabIndicatorProps={{
                    children: <span />,
                }}
                {...rest}
            />
            {!hideDivider && <div style={customDividerStyles} className={classes.divider} />}
        </div>
    );
};
