import React, { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import css from './LayoutFixedItem.module.css';

export const LayoutFixedItem: FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = props => {
    const { className, ...rest } = props;

    return (
        <div className={cn(css.item, className)} {...rest}>
            {props.children}
        </div>
    );
};
