import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useAction = <T>(): ((actionCreator: (...args: any[]) => T, ...args: any[]) => void) => {
    const dispatch = useDispatch();
    return (actionCreator, ...args) => {
        const action = actionCreator.apply(null, args);
        dispatch(action);
    };
};

export const useActionDispatchOnMount = <T>(
    shouldDispatchAction: boolean,
    actionCreator: (...args: any[]) => T,
    ...args: any[]
) => {
    const dispatch = useDispatch();
    const action = actionCreator.apply(null, args);
    useEffect(() => {
        if (shouldDispatchAction) {
            dispatch(action);
        }
        // eslint-disable-next-line
    }, []);
};

export const useActivate = <A, D>(activate: A, deactivate: D) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: activate,
        });
        return () => {
            dispatch({
                type: deactivate,
            });
        };
        // eslint-disable-next-line
    }, []);
};
