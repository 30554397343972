import React, { FC, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import i18n from '../../../i18n';

const MuiPhoneNumber = dynamic<any>(() => import('material-ui-phone-number'), {
    ssr: false,
    loading: () => <p>Loading MuiPhoneNumber</p>,
});
import { WrappedFieldProps } from 'redux-form';
import { NoSsr } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getCountryCode } from '../../core/utils/utils';

export interface TextControlProps {
    label: string;
    // Optional:
    autoFocus?: boolean;
    name?: string;
    value?: string;
    onChange?: any;
    error?: string;
    fullWidth?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
}

const useInputStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(0.75),
        paddingLeft: 0,
        position: 'relative',
        '&:after': {
            color: theme.palette.fixedDanger.main,
            content: '"✱"',
            fontSize: 16,
            lineHeight: '48px',
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(0),
        },
    },
}));

export const MobilePhoneControl: FC<TextControlProps> = props => {
    const { autoFocus, isDisabled, error, onChange, isRequired, ...rest } = props;

    const handleChange = useCallback((value: any) => onChange(value), []);

    // TODO: lift up outside this component
    const countryCode = useMemo(getCountryCode, []);

    const classes = useInputStyles();
    return (
        <NoSsr>
            <MuiPhoneNumber
                autoFocus={autoFocus}
                disabled={isDisabled}
                onlyCountries={['ru', 'it', 'us', 'gb', 'nl', 'rs', 'uz']}
                disableAreaCodes={true}
                disableDropdown={isDisabled}
                defaultCountry={countryCode}
                InputProps={{ disableUnderline: true, classes: isRequired ? classes : null }}
                variant="filled"
                error={!!error}
                helperText={error}
                fullWidth={true}
                size="small"
                onChange={handleChange}
                {...rest}
            />
        </NoSsr>
    );
};

export interface MobilePhoneControlFieldOwnProps {
    label: string;
    // Optional:
    autoFocus?: boolean;
    isDisabled?: boolean;
    isRequired?: boolean;
}

export type MobilePhoneControlFieldProps = MobilePhoneControlFieldOwnProps & WrappedFieldProps;

export const MobilePhoneControlField = (props: MobilePhoneControlFieldProps) => {
    const { t } = i18n.useTranslation();
    const {
        autoFocus,
        input,
        isDisabled,
        isRequired,
        label,
        meta: { touched, invalid, error },
    } = props;
    return (
        <MobilePhoneControl
            autoFocus={autoFocus}
            isDisabled={isDisabled}
            isRequired={isRequired}
            label={label}
            error={touched && invalid ? t(error) : undefined}
            {...input}
        />
    );
};
