import { Box } from '@material-ui/core';
import { Icon } from '../../components/Icon/Icon';
import { ReactComponent as CheckCircle } from '../../assets/icons/status/check-circle--16.svg';
import { Text, TextTypography } from '../../components/Text/Text';
import { makeUserMenuNavigateAction, makeUserMenuShowAction } from '../../state/userMenu/actions';
import { UserMenuSelectedItem } from '../../state/userMenu/reducer';
import { RoutePath } from '../../RoutePath';
import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAction } from '../../core/store/store';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'left',
        justifyContent: 'center',
    },
    successMessageTitle: {
        marginBottom: theme.spacing(1),
        display: 'block',
    },
    successButtons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        [theme.breakpoints.down(1340)]: {
            flexDirection: 'column',
        },
    },
    buttonElement: {
        color: '#45CA52',
        cursor: 'pointer',
    },
}));

interface CheckoutSuccessProps {
    orderId: string;
}

export const CheckoutSuccess = ({ orderId }: CheckoutSuccessProps) => {
    const action = useAction();
    const { t } = i18n.useTranslation();

    const classes = useStyles();

    const handleNavigateToOrders = useCallback(() => {
        action(makeUserMenuShowAction);
        action(makeUserMenuNavigateAction, UserMenuSelectedItem.ORDERS);
    }, [action]);

    const handleNavigateToOrder = useCallback(() => {
        action(makeUserMenuShowAction);
        action(makeUserMenuNavigateAction, UserMenuSelectedItem.ORDER, { orderId });
    }, [action, orderId]);

    const handleContinueShopping = useCallback(() => {
        window.location.assign(RoutePath.FEED);
    }, []);

    return (
        <div className={classes.container}>
            <Box m={0.5} mr={2}>
                <Icon component={CheckCircle} svgSize={16} size={16} />
            </Box>
            <div>
                <Text className={classes.successMessageTitle} typography={TextTypography.LEAD_ACCENT}>
                    {t('feed:checkout.success.title')}
                </Text>
                <Text typography={TextTypography.PARA}>
                    {t('feed:checkout.success.text1')}
                    <span className={classes.buttonElement} onClick={handleNavigateToOrders}>
                        {t('feed:checkout.success.text2')}
                    </span>
                    {/*{t('feed:checkout.success.text3')}*/}
                </Text>
                <div className={classes.successButtons}>
                    <div onClick={handleNavigateToOrder}>
                        <Text className={classes.buttonElement} typography={TextTypography.PARA_ACCENT}>
                            {t('feed:checkout.success.toOrder')}
                        </Text>
                    </div>
                    <div onClick={handleContinueShopping}>
                        <Text className={classes.buttonElement} typography={TextTypography.PARA_ACCENT}>
                            {t('feed:checkout.success.continue')}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
