import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.brandPrimary.accent,
        height: 1,
        width: '100%',
    },
}));

export interface HorizontalDividerProps {
    className: string;
}

export const HorizontalDivider = (props: Partial<HorizontalDividerProps>) => {
    const classes = useStyles();
    return <div className={classNames(classes.root, props.className)} />;
};

const useLargeStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.brandPrimary.accent,
        height: 8,
        width: '100%',
    },
}));

export const LargeHorizontalDivider = () => {
    const classes = useLargeStyles();
    return <div className={classes.root} />;
};
